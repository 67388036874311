import React, { useState } from 'react';
import { List, Space, Progress, Tag, Button, Badge, Card, message, DatePicker, Modal } from 'antd';
import { ClockCircleOutlined, InboxOutlined, MessageOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import api from '../../services/api';
import MessagesModal from './MessagesModal';

const RoomsList = ({ rooms, onRoomsUpdate }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [newExpiryDate, setNewExpiryDate] = useState(null);
    const [messagesModalVisible, setMessagesModalVisible] = useState(false);
    const [currentRoomId, setCurrentRoomId] = useState(null);

    const calculateTotalDays = (createdAt, expiresAt) => {
        const createdDate = dayjs(createdAt);
        const expireDate = dayjs(expiresAt);
        return expireDate.diff(createdDate, 'day') + 1;
    };

    const calculateDaysLeft = (expiresAt) => {
        const now = dayjs();
        const expireDate = dayjs(expiresAt);
        return expireDate.diff(now, 'day');
    };

    const getProgressColor = (daysLeft) => {
        if (daysLeft <= 1) return '#f5222d';
        if (daysLeft <= 3) return '#faad14';
        return '#52c41a';
    };

    const handleArchiveToggle = async (roomId, isArchived) => {
        try {
            await api.put(`/rooms/${roomId}`, { is_archived: !isArchived });
            message.success(`Комната успешно ${isArchived ? 'разархивирована' : 'архивирована'}`);
            onRoomsUpdate();
        } catch (error) {
            message.error(`Ошибка при ${isArchived ? 'разархивировании' : 'архивировании'} комнаты`);
        }
    };

    const showModal = (room) => {
        setSelectedRoom(room);
        setNewExpiryDate(dayjs(room.expires_at));
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
            await api.put(`/rooms/${selectedRoom.id}`, {
                expires_at: newExpiryDate.toISOString()
            });
            message.success('Дата истечения успешно обновлена');
            setIsModalVisible(false);
            onRoomsUpdate();
        } catch (error) {
            message.error('Ошибка при обновлении даты истечения');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const openMessagesModal = (roomId) => {
        setCurrentRoomId(roomId);
        setMessagesModalVisible(true);
    };

    return (
        <>
            <List
                itemLayout="vertical"
                size="large"
                dataSource={rooms.sort((a, b) => b.id - a.id)}
                renderItem={(room) => {
                    const daysLeft = calculateDaysLeft(room.expires_at);
                    const progressColor = getProgressColor(daysLeft);
                    const totalDays = calculateTotalDays(room.created_at, room.expires_at);
                    const percent = ((totalDays / daysLeft) - 1) * 100;

                    return (
                        <Badge.Ribbon
                            text={room.is_archived ? "Архив" : "Активна"}
                            color={room.is_archived ? "default" : "blue"}
                        >
                            <Card
                                hoverable
                                style={{ marginBottom: 16 }}
                            >
                                <List.Item
                                    key={room.id}
                                    actions={[
                                        <Button icon={<ClockCircleOutlined />} onClick={() => showModal(room)}>Изменить срок</Button>,
                                        <Button icon={<InboxOutlined />} onClick={() => handleArchiveToggle(room.id, room.is_archived)}>
                                            {room.is_archived ? 'Разархивировать' : 'В архив'}
                                        </Button>,
                                        <Button icon={<MessageOutlined />} onClick={() => openMessagesModal(room.id)}>Читать сообщения</Button>
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={<h3>Комната #{room.id}</h3>}
                                        description={
                                            <Space direction="vertical" size="small" style={{ width: '100%' }}>
                                                <Tag color="geekblue">Создана: {dayjs(room.created_at).format('DD.MM.YYYY HH:mm')}</Tag>
                                                <Progress
                                                    percent={percent}
                                                    strokeColor={progressColor}
                                                    format={() => `${daysLeft} дней осталось`}
                                                />
                                            </Space>
                                        }
                                    />
                                    <Space direction="vertical" size="small" style={{ width: '100%' }}>
                                        <Card size="small" title="Информация о пациенте" style={{ marginBottom: 16 }}>
                                            <p><strong>ФИО:</strong> {room.patientName}</p>
                                            <p><strong>Возраст:</strong> {room.patient.age || 'Не указан'}</p>
                                            <p><strong>Диагноз:</strong> {room.patient.diagnosis || 'Не указан'}</p>
                                            <p><strong>Вес:</strong> {room.patient.weight ? `${room.patient.weight} кг` : 'Не указан'}</p>
                                            <Tag color="green">{room.patient.position}</Tag>
                                        </Card>
                                        <Card size="small" title="Информация о специалисте">
                                            <p><strong>ФИО:</strong> {room.specialistName}</p>
                                            <p><strong>Должность:</strong> {room.specialist.position}</p>
                                            <Tag color="blue">{room.specialist.role}</Tag>
                                        </Card>
                                    </Space>
                                </List.Item>
                            </Card>
                        </Badge.Ribbon>
                    );
                }}
            />
            <Modal
                title="Изенить срок действия комнаты"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <DatePicker
                    showTime
                    value={newExpiryDate}
                    onChange={setNewExpiryDate}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Modal>
            <MessagesModal
                roomId={currentRoomId}
                visible={messagesModalVisible}
                onClose={() => setMessagesModalVisible(false)}
            />
        </>
    );
};

export default RoomsList;