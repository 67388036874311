import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const success = await auth.login({ username, password });
            if (success) {
                const { from } = location.state || { from: { pathname: "/" } };
                navigate(from);
            } else {
                setError('Invalid username or password');
            }
        } catch (error) {
            setError('An error occurred during login');
            console.error('Login error', error);
        }
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '300px',
        margin: '0 auto',
    };

    const inputStyle = {
        margin: '10px 0',
        padding: '5px',
    };

    const buttonStyle = {
        padding: '10px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    };

    const errorStyle = {
        color: 'red',
        marginTop: '10px',
    };

    return (
        <form onSubmit={handleSubmit} style={formStyle}>
            <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                style={inputStyle}
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                style={inputStyle}
            />
            <button type="submit" style={buttonStyle}>Login</button>
            {error && <div style={errorStyle}>{error}</div>}
        </form>
    );
};

export default Login;