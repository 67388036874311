import React, { useState, useEffect } from 'react';
import { Flex, Input, Segmented, Spin, Button, Select, DatePicker, Modal, message } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import api from "../../services/api";
import RoomsList from "../specific/RoomsList";
import RoomsCards from "../specific/RoomsCards";

const { Search } = Input;

const RoomsContent = () => {
    const [loading, setLoading] = useState(true);
    const [rooms, setRooms] = useState([]);
    const [filteredRooms, setFilteredRooms] = useState([]);
    const [viewMode, setViewMode] = useState('List');
    const [archiveMode, setArchiveMode] = useState('Active');
    const [searchQuery, setSearchQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedSpecialist, setSelectedSpecialist] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);

    // Загрузка списка пользователей
    const fetchUsers = async () => {
        try {
            const response = await api.get('/users');
            console.log('Users fetched:', response.data); // Отладочный вывод пользователей
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Загрузка списка комнат
    const fetchRooms = async () => {
        try {
            const response = await api.get('/rooms');
            const formattedRooms = response.data.map((room) => ({
                ...room,
                key: room.id,
                patientName: `${room.patient.last_name} ${room.patient.first_name} ${room.patient.middle_name}`.trim(),
                specialistName: `${room.specialist.last_name} ${room.specialist.first_name} ${room.specialist.middle_name}`.trim(),
                expiresAt: new Date(room.expires_at).toLocaleString('ru-RU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }),
                createdAt: new Date(room.created_at).toLocaleString('ru-RU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                }),
            }));
            setRooms(formattedRooms);
            setFilteredRooms(formattedRooms);
        } catch (error) {
            console.error('Error fetching rooms:', error);
        } finally {
            setLoading(false);
        }
    };

    const createRoom = async () => {
        try {
            await api.post('/rooms', {
                patient_id: selectedPatient,
                specialist_id: selectedSpecialist,
                expires_at: expiresAt
            });
            message.success('Комната успешно создана');
            setModalVisible(false);
            fetchRooms();  // Принудительная перезагрузка комнат
        } catch (error) {
            message.error('Ошибка при создании комнаты');
        }
    };

    useEffect(() => {
        fetchRooms();
        fetchUsers(); // Загрузка пользователей при загрузке компонента
    }, []);

    useEffect(() => {
        const filtered = rooms.filter(room =>
            (archiveMode === 'Active' ? !room.is_archived : room.is_archived) &&
            (room.patientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                room.specialistName.toLowerCase().includes(searchQuery.toLowerCase()))
        );
        setFilteredRooms(filtered);
    }, [searchQuery, archiveMode, rooms]);

    const handleSearch = (value) => {
        setSearchQuery(value);
    };

    const handleViewModeChange = (value) => {
        setViewMode(value);
    };

    const handleArchiveModeChange = (value) => {
        setArchiveMode(value === 'Active' ? 'Active' : 'Archived');
    };

    const handleCreateRoom = () => {
        setModalVisible(true);
    };

    // Создание массива options для Select
    const getUserOptions = (role) => {
        return users
            .filter(user => user.role === role)
            .map(user => ({
                value: user.id,
                label: `${user.last_name} ${user.first_name} ${user.middle_name} (${user.position})`
            }));
    };

    if (loading) {
        return <Spin size="large" />;
    }

    return (
        <div>
            <Flex align="center" justify="space-between" style={{ marginBottom: 16 }}>
                <Search
                    placeholder="Поиск по имени пациента или специалиста"
                    enterButton
                    style={{ width: 400 }}
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <Flex align="center" justify="flex-end" gap={20}>
                    <Button type="primary" onClick={handleCreateRoom}>Создать комнату</Button>
                    <Segmented
                        options={[
                            { label: 'Активные', value: 'Active' },
                            { label: 'Архивные', value: 'Archived' },
                        ]}
                        onChange={handleArchiveModeChange}
                    />
                    <Segmented
                        options={[
                            { value: 'List', icon: <BarsOutlined /> },
                            { value: 'Cards', icon: <AppstoreOutlined /> },
                        ]}
                        onChange={handleViewModeChange}
                    />
                </Flex>
            </Flex>
            {viewMode === 'List' ? (
                <RoomsList rooms={filteredRooms} onRoomsUpdate={fetchRooms} />
            ) : (
                <RoomsCards rooms={filteredRooms} onRoomsUpdate={fetchRooms} />
            )}

            <Modal
                title="Создание новой комнаты"
                visible={modalVisible}
                onOk={createRoom}
                onCancel={() => setModalVisible(false)}
            >
                <Select
                    style={{ width: '100%', marginBottom: 16 }}
                    placeholder="Выберите пациента"
                    options={getUserOptions('patient')}
                    onChange={setSelectedPatient}
                />
                <Select
                    style={{ width: '100%', marginBottom: 16 }}
                    placeholder="Выберите специалиста"
                    options={getUserOptions('specialist')}
                    onChange={setSelectedSpecialist}
                />
                <DatePicker
                    style={{ width: '100%' }}
                    showTime
                    onChange={(value) => setExpiresAt(value.toISOString())}
                    placeholder="Выберите дату окончания"
                />
            </Modal>
        </div>
    );
};

export default RoomsContent;