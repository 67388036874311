// src/pages/TelegramPage.js
import React, { useEffect, useState, useRef } from 'react';
import { List, NavBar, ProgressCircle, SearchBar, Badge } from 'antd-mobile';
import dayjs from 'dayjs';
import api from "../services/api";
import { Flex } from "antd";
import ChatRoom from "../components/specific/ChatRoom";

const getProgressColor = (daysRemaining) => {
    if (daysRemaining <= 3) {
        return 'var(--adm-color-danger)';
    } else if (daysRemaining <= 7) {
        return 'var(--adm-color-warning)';
    } else {
        return 'var(--adm-color-primary)';
    }
};

const ChatItem = ({ chat }) => {
    const { patient, created_at, expires_at, unread_messages } = chat;
    const now = dayjs();
    const totalDays = dayjs(expires_at).diff(dayjs(created_at), 'day');
    const daysPassed = now.diff(dayjs(created_at), 'day');
    const daysRemaining = dayjs(expires_at).diff(now, 'day');

    const percent = (daysPassed / totalDays) * 100;

    const firstUnreadMessage = unread_messages.length > 0 ? unread_messages[0].content : '';
    const messagePreview = firstUnreadMessage.substring(0, 70);

    const formatDate = (date) => {
        const now = dayjs();
        const messageDate = dayjs(date);
        if (now.isSame(messageDate, 'day')) {
            return messageDate.format('HH:mm');
        } else {
            return messageDate.format('DD/MM/YYYY');
        }
    };

    return (
        <List.Item
            key={chat.id}
            prefix={
                <ProgressCircle
                    percent={percent}
                    style={{
                        '--fill-color': getProgressColor(daysRemaining),
                        width: '50px',
                        height: '50px'
                    }}
                >
                    {daysRemaining}
                </ProgressCircle>
            }
            description={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{messagePreview}</span>
                    <span>{formatDate(unread_messages.length > 0 ? unread_messages[0].created_at : created_at)}</span>
                </div>
            }
        >
            <div>
                <Flex align={'center'} justify={'space-between'}>
                    <>{patient.last_name} {patient.first_name.charAt(0).toUpperCase()}. {patient.middle_name.charAt(0).toUpperCase()}.</>
                    {unread_messages.length > 0 && (
                        <Badge content={unread_messages.length > 99 ? '99+' : unread_messages.length} style={{ marginLeft: '8px', scale: '1.5' }} color={'#d6d6d6'}/>
                    )}
                </Flex>
            </div>
        </List.Item>
    );
};

const ChatList = ({ chats, showArchived, setShowArchived, onChatSelect }) => {
    return (
        <List>
            {!showArchived && (
                <List.Item onClick={() => setShowArchived(true)}>Архив</List.Item>
            )}
            {chats.map(chat => (
                <div key={chat.id} onClick={() => onChatSelect(chat)}>
                    <ChatItem chat={chat} />
                </div>
            ))}
        </List>
    );
};

const TelegramPage = () => {
    const [activeChats, setActiveChats] = useState([]);
    const [archivedChats, setArchivedChats] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [showHeader, setShowHeader] = useState(true);
    const [selectedChat, setSelectedChat] = useState(null);
    const intervalRef = useRef(null);
    const scrollRef = useRef(null);
    const lastScrollTop = useRef(0);

    const fetchChats = async () => {
        try {
            const response = await api.get('/specialist/rooms');
            const chats = response.data;
            setActiveChats(chats.filter(chat => !chat.is_archived));
            setArchivedChats(chats.filter(chat => chat.is_archived));
            console.log('Chats updated');
        } catch (error) {
            console.error('Ошибка при получении чатов:', error);
        }
    };

    useEffect(() => {
        fetchChats();
        intervalRef.current = setInterval(fetchChats, 4000);
        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = scrollRef.current.scrollTop;
            if (scrollTop > lastScrollTop.current) {
                setShowHeader(false);
            } else {
                setShowHeader(true);
            }
            lastScrollTop.current = scrollTop;
        };

        const currentScrollRef = scrollRef.current;
        currentScrollRef.addEventListener('scroll', handleScroll);

        return () => {
            currentScrollRef.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const filteredChats = (showArchived ? archivedChats : activeChats).filter(chat =>
        chat.patient.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        chat.specialist.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleChatSelect = (chat) => {
        setSelectedChat(chat);
    };

    const handleBackFromChat = () => {
        setSelectedChat(null);
    };

    if (selectedChat) {
        return (
            <ChatRoom
                roomId={selectedChat.id}
                patientName={`${selectedChat.patient.last_name} ${selectedChat.patient.first_name.charAt(0).toUpperCase()}. ${selectedChat.patient.middle_name.charAt(0).toUpperCase()}.`}
                onBack={handleBackFromChat}
                patientTelegramId={selectedChat.patient.telegram_id}
            />
        );
    }

    return (
        <div ref={scrollRef}>
            {showHeader && (
                <NavBar>Чаты</NavBar>
            )}
            <SearchBar
                value={searchQuery}
                onChange={setSearchQuery}
                placeholder="Поиск"
            />
            <ChatList
                chats={filteredChats}
                showArchived={showArchived}
                setShowArchived={setShowArchived}
                onChatSelect={handleChatSelect}
            />
        </div>
    );
};

export default TelegramPage;