import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import {
    AppstoreOutlined, BarChartOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, theme } from 'antd';
import UsersContent from "../components/contents/Users";
import AnalyticsContent from "../components/contents/Analytics";
import RoomsContent from "../components/contents/Rooms";

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState('1');
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const { logout } = useAuth();

    const renderContent = () => {
        switch (selectedMenuItem) {
            case '1':
                return <UsersContent />;
            case '2':
                return <RoomsContent />;
            case '3':
                return <AnalyticsContent />;
            default:
                return <UsersContent />;
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ position: 'fixed', height: '100vh' }}>
                <div className="demo-logo-vertical" />
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    onSelect={({ key }) => setSelectedMenuItem(key)}
                    items={[
                        {
                            key: '1',
                            icon: <UserOutlined />,
                            label: 'Пользователи',
                        },
                        {
                            key: '2',
                            icon: <AppstoreOutlined />,
                            label: 'Комнаты',
                        },
                        {
                            key: '3',
                            icon: <BarChartOutlined />,
                            label: 'Аналитика',
                        },
                    ]}
                />
                <Button
                    type="text"
                    onClick={logout}
                    style={{
                        position: 'absolute',
                        bottom: 20,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        color: 'white',
                        backgroundColor: "blue",
                    }}
                >
                    Выйти
                </Button>
            </Sider>
            <Layout style={{ marginLeft: collapsed ? 80 : 200, transition: 'margin-left 0.2s' }}>
                <Header style={{ padding: 0, background: colorBgContainer }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 'calc(100vh - 64px)',
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    {renderContent()}
                </Content>
            </Layout>
        </Layout>
    );
};

export default Dashboard;