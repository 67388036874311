import React, { useState, useEffect, useRef, useCallback } from 'react';
import { NavBar, Input, Button, Toast, Popover } from 'antd-mobile';
import { SendOutline, MoreOutline } from 'antd-mobile-icons';
import dayjs from 'dayjs';
import './ChatRoom.css';
import api from "../../services/api";
import axios from "axios";

const ChatRoom = ({ roomId, patientName, onBack, patientTelegramId }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [editingMessageId, setEditingMessageId] = useState(null);
    const messagesEndRef = useRef(null);

    const fetchMessages = useCallback(async () => {
        try {
            const response = await api.get(`/rooms/${roomId}/messages`);
            setMessages(response.data);
        } catch (error) {
            console.error('Ошибка при получении сообщений:', error);
        }
    }, [roomId]);

    const setMessageReaction = useCallback(async (tgMsgId, reactionEmoji) => {
        if (!tgMsgId) {
            console.error('tg_msg_id отсутствует');
            return;
        }

        try {
            await axios.post(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/setMessageReaction`, {
                chat_id: String(patientTelegramId),
                message_id: tgMsgId,
                reaction: [{ type: "emoji", emoji: reactionEmoji }],
                is_big: true
            });
            console.log('Реакция успешно установлена');
        } catch (error) {
            console.error('Ошибка при установке реакции на сообщение в Telegram:', error.response ? error.response.data : error.message);
            Toast.show({
                content: 'Не удалось установить реакцию на сообщение в Telegram',
                position: 'bottom'
            });
        }
    }, [patientTelegramId]);

    const markMessageAsRead = useCallback(async (messageId) => {
        try {
            const message = messages.find(msg => msg.id === messageId);
            if (message && !message.is_read) {
                await api.put(`/messages/${messageId}/read`, { is_read: true });
                console.log('Сообщение отмечено как прочитанное:', messageId);

                // Проверка роли пользователя сообщения
                if (message.user.role !== 'specialist' && message.tg_msg_id) {
                    await setMessageReaction(message.tg_msg_id, "👀");
                    console.log('Реакция установлена для сообщения:', message.tg_msg_id);
                } else {
                    console.warn('Реакция не установлена для сообщения:', messageId);
                }

                setMessages(prevMessages =>
                    prevMessages.map(msg =>
                        msg.id === messageId ? { ...msg, is_read: true } : msg
                    )
                );
            }
        } catch (error) {
            console.error('Ошибка при отметке сообщения как прочитанного:', error);
        }
    }, [messages, setMessageReaction]);

    const sendMessage = async () => {
        if (!inputMessage.trim()) return;

        try {
            const telegramResponse = await axios.post(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/sendMessage`, {
                chat_id: patientTelegramId,
                text: inputMessage
            });

            const tgMsgId = telegramResponse.data.result.message_id;

            const response = await api.post('/messages', {
                room_id: roomId,
                tg_msg_id: tgMsgId,
                content: inputMessage
            });

            setMessages([...messages, response.data]);
            setInputMessage('');
        } catch (error) {
            console.error('Ошибка при отправке сообщения:', error);
            Toast.show({
                content: 'Не удалось отправить сообщение',
                position: 'bottom'
            });
        }
    };

    const editTelegramMessage = async (tgMsgId, newContent) => {
        if (!tgMsgId) {
            console.error('tg_msg_id отсутствует');
            return;
        }

        try {
            await axios.post(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/editMessageText`, {
                chat_id: String(patientTelegramId),
                message_id: tgMsgId,
                text: newContent,
                parse_mode: "HTML",
                reply_markup: JSON.stringify({})
            });
        } catch (error) {
            console.error('Ошибка при редактировании сообщения в Telegram:', error.response ? error.response.data : error.message);
            Toast.show({
                content: 'Не удалось отредактировать сообщение в Telegram',
                position: 'bottom'
            });
        }
    };

    const editMessage = async (messageId, newContent) => {
        try {
            const message = messages.find(msg => msg.id === messageId);
            if (message) {
                await editTelegramMessage(message.tg_msg_id, newContent);
                await api.put(`/messages/${messageId}`, { content: newContent });
                setMessages(messages.map(msg =>
                    msg.id === messageId ? { ...msg, content: newContent } : msg
                ));
                setEditingMessageId(null);
            }
        } catch (error) {
            console.error('Ошибка при редактировании сообщения:', error);
            Toast.show({
                content: 'Не удалось отредактировать сообщение',
                position: 'bottom'
            });
        }
    };

    const deleteTelegramMessage = async (tgMsgId) => {
        if (!tgMsgId) {
            console.error('tg_msg_id отсутствует');
            return;
        }

        try {
            await axios.post(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/deleteMessage`, {
                chat_id: String(patientTelegramId),
                message_id: tgMsgId
            });
        } catch (error) {
            console.error('Ошибка при удалении сообщения в Telegram:', error.response ? error.response.data : error.message);
            Toast.show({
                content: 'Не удалось удалить сообщение в Telegram',
                position: 'bottom'
            });
        }
    };

    const deleteMessage = async (messageId) => {
        try {
            const message = messages.find(msg => msg.id === messageId);
            if (message) {
                await deleteTelegramMessage(message.tg_msg_id);
                await api.delete(`/messages/${messageId}`);
                setMessages(messages.filter(msg => msg.id !== messageId));
            }
        } catch (error) {
            console.error('Ошибка при удалении сообщения:', error);
            Toast.show({
                content: 'Не удалось удалить сообщение',
                position: 'bottom'
            });
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        fetchMessages();
        const interval = setInterval(fetchMessages, 3000);
        return () => clearInterval(interval);
    }, [roomId, fetchMessages]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const messageId = entry.target.getAttribute('data-message-id');
                        if (messageId) {
                            markMessageAsRead(parseInt(messageId, 10));
                        }
                    }
                });
            },
            { threshold: 1.0 }
        );

        messages.forEach((message) => {
            if (!message.is_read) {
                const element = document.getElementById(`message-${message.id}`);
                if (element) {
                    observer.observe(element);
                }
            }
        });

        return () => observer.disconnect();
    }, [messages, markMessageAsRead]);

    return (
        <div className="chat-room">
            <NavBar onBack={onBack}>{patientName}</NavBar>
            <div className="message-list">
                {messages.map((message) => (
                    <div
                        key={message.id}
                        id={`message-${message.id}`}
                        data-message-id={message.id}
                        className={`message ${message.user.role === 'specialist' ? 'message-sent' : 'message-received'}`}
                    >
                        {editingMessageId === message.id ? (
                            <Input
                                value={message.content}
                                onChange={(value) => {
                                    setMessages(messages.map(msg =>
                                        msg.id === message.id ? { ...msg, content: value } : msg
                                    ));
                                }}
                                onBlur={() => editMessage(message.id, message.content)}
                            />
                        ) : (
                            <>
                                <div className="message-content">{message.content}</div>
                                <div className="message-time">{dayjs(message.created_at).format('HH:mm')}</div>
                                {message.user.role === 'specialist' && (
                                    <Popover
                                        content={
                                            <div className="message-actions">
                                                <Button onClick={() => setEditingMessageId(message.id)}>Редактировать</Button>
                                                <Button onClick={() => deleteMessage(message.id)}>Удалить</Button>
                                            </div>
                                        }
                                        trigger="click"
                                        placement="topLeft"
                                    >
                                        <MoreOutline className="message-more" />
                                    </Popover>
                                )}
                            </>
                        )}
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="message-input">
                <Input
                    placeholder="Введите сообщение"
                    value={inputMessage}
                    onChange={setInputMessage}
                />
                <Button onClick={sendMessage}><SendOutline /></Button>
            </div>
        </div>
    );
};

export default ChatRoom;