// src/components/specific/MessagesModal.js
import React, { useState, useEffect } from 'react';
import {Modal, List, Spin, Typography, Tag, Flex} from 'antd';
import dayjs from 'dayjs';
import api from '../../services/api';

const { Text } = Typography;

const roleTranslations = {
    specialist: 'Специалист',
    patient: 'Пациент'
};

const roleColors = {
    specialist: 'cyan',
    patient: 'green'
};

const MessagesModal = ({ roomId, visible, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (visible) {
            fetchMessages();
        }
    }, [visible]);

    const fetchMessages = async () => {
        try {
            setLoading(true);
            const response = await api.get(`/rooms/${roomId}/messages`);
            setMessages(response.data);
        } catch (error) {
            console.error('Ошибка при получении соо��щений:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Сообщения чата"
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={600}
        >
            {loading ? (
                <Spin size="large" style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }} />
            ) : messages.length === 0 ? (
                <div style={{ textAlign: 'center', padding: 20 }}>
                    <Text type="secondary">Сообщений пока нет</Text>
                </div>
            ) : (
                <List
                    itemLayout="vertical"
                    size="small"
                    dataSource={messages}
                    renderItem={message => (
                        <List.Item
                            key={message.id}
                            style={{
                                backgroundColor: message.is_read ? '#f0f0f0' : '#fff',
                                padding: '10px 15px',
                                borderRadius: 8,
                                marginBottom: 10,
                                display: 'flex',
                                justifyContent: message.user.role === 'patient' ? 'flex-start' : 'flex-end'
                            }}
                        >
                            <div style={{ maxWidth: '70%' , minWidth: '150px'}}>
                                <List.Item.Meta
                                    title={
                                        <div style={{ minWidth: '200px' }}>
                                            <Flex align={'center'} gap={5} justify={'flex-start'}>
                                            <Text strong>{message.user.username}</Text>
                                            <Tag color={roleColors[message.user.role]}>
                                                {roleTranslations[message.user.role]}
                                            </Tag>
                                            </Flex>
                                        </div>
                                    }
                                    description={dayjs(message.created_at).format('DD.MM.YYYY HH:mm')}
                                />
                                <Text>{message.content}</Text>
                            </div>
                        </List.Item>
                    )}
                />
            )}
        </Modal>
    );
};

export default MessagesModal;