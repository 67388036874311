import React, { useState, useEffect } from 'react';
import api from '../services/api';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/users');
                setUsers(response.data);
            } catch (error) {
                console.error('Failed to fetch users', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const containerStyle = {
        padding: '20px',
    };

    const listStyle = {
        listStyle: 'none',
        padding: 0,
    };

    const listItemStyle = {
        padding: '10px',
        borderBottom: '1px solid #ccc',
    };

    if (isLoading) {
        return <div style={containerStyle}>Loading users...</div>;
    }

    return (
        <div style={containerStyle}>
            <h1>Users</h1>
            <ul style={listStyle}>
                {users.map((user) => (
                    <li key={user.id} style={listItemStyle}>
                        {user.full_name} ({user.role})
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Users;