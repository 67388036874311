import React, { createContext, useState, useEffect } from 'react';
import api from '../services/api';
import { setItem, getItem, removeItem } from '../services/storage';


export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(getItem('token'));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const verifyToken = async () => {
            if (token) {
                try {
                    const response = await api.get('/verify-token');
                    setUser(response.data);
                } catch (error) {
                    console.error('Invalid token', error);
                    logout();
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        verifyToken();
    }, [token]);

    const login = async (credentials) => {
        try {
            const response = await api.post('/auth/login', new URLSearchParams({
                grant_type: 'password',
                username: credentials.username,
                password: credentials.password,
                scope: '',
                client_id: 'string',
                client_secret: 'string',
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            const { access_token, token_type } = response.data;
            const formattedTokenType = token_type.charAt(0).toUpperCase() + token_type.slice(1);
            const fullToken = `${formattedTokenType} ${access_token}`;
            setItem('token', fullToken);
            setToken(fullToken);

            const verifyResponse = await api.get('/verify-token');
            setUser(verifyResponse.data);

            return true;
        } catch (error) {
            console.error('Login failed', error);
            return false;
        }
    };

    const logout = () => {
        removeItem('token');
        setToken(null);
        setUser(null);
    };

    const value = {
        user,
        token,
        isLoading,
        isAuthenticated: !!token,
        login,
        logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};