import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {useAuth} from "../hooks/useAuth";
import {Spin} from "antd";

const PrivateRoute = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();

    if (auth.isLoading) {
        return <Spin tip="Loading" size="large" />;
    }

    if (!auth.isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default PrivateRoute;