// src/services/storage.js
const isLocalStorageSupported = () => {
    try {
        const testKey = '__test__';
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

const isSessionStorageSupported = () => {
    try {
        const testKey = '__test__';
        sessionStorage.setItem(testKey, testKey);
        sessionStorage.removeItem(testKey);
        return true;
    } catch (e) {
        return false;
    }
};

let storage;
if (isLocalStorageSupported()) {
    storage = localStorage;
} else if (isSessionStorageSupported()) {
    storage = sessionStorage;
} else {
    const memoryStorage = {};
    storage = {
        setItem: (key, value) => {
            memoryStorage[key] = value;
        },
        getItem: (key) => {
            return memoryStorage[key];
        },
        removeItem: (key) => {
            delete memoryStorage[key];
        }
    };
}

export const setItem = (key, value) => {
    storage.setItem(key, value);
};

export const getItem = (key) => {
    return storage.getItem(key);
};

export const removeItem = (key) => {
    storage.removeItem(key);
};