import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    const notFoundStyle = {
        textAlign: 'center',
        marginTop: '50px',
    };

    const linkStyle = {
        color: '#007bff',
        textDecoration: 'none',
    };

    return (
        <div style={notFoundStyle}>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <Link to="/" style={linkStyle}>Go to Home</Link>
        </div>
    );
};

export default NotFound;