// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-room {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #f5f5f5;
}

.message-list {
    flex: 1 1;
    overflow-y: auto;
    padding: 16px;
}

.message {
    max-width: 70%;
    margin-bottom: 16px;
    padding: 12px;
    border-radius: 12px;
    position: relative;
}

.message-sent {
    align-self: flex-end;
    background-color: #dcf8c6;
    margin-left: auto;
}

.message-received {
    align-self: flex-start;
    background-color: #ffffff;
}

.message-content {
    word-wrap: break-word;
}

.message-time {
    font-size: 12px;
    color: #999;
    text-align: right;
    margin-top: 4px;
}

.message-more {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}

.message-actions {
    display: flex;
    flex-direction: column;
}

.message-input {
    display: flex;
    padding: 16px;
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;
}

.message-input .adm-input {
    flex: 1 1;
    margin-right: 8px;
}

.message-input .adm-button {
    padding: 0 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/specific/ChatRoom.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,aAAa;IACb,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA;IACI,SAAO;IACP,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".chat-room {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n    background-color: #f5f5f5;\n}\n\n.message-list {\n    flex: 1;\n    overflow-y: auto;\n    padding: 16px;\n}\n\n.message {\n    max-width: 70%;\n    margin-bottom: 16px;\n    padding: 12px;\n    border-radius: 12px;\n    position: relative;\n}\n\n.message-sent {\n    align-self: flex-end;\n    background-color: #dcf8c6;\n    margin-left: auto;\n}\n\n.message-received {\n    align-self: flex-start;\n    background-color: #ffffff;\n}\n\n.message-content {\n    word-wrap: break-word;\n}\n\n.message-time {\n    font-size: 12px;\n    color: #999;\n    text-align: right;\n    margin-top: 4px;\n}\n\n.message-more {\n    position: absolute;\n    top: 8px;\n    right: 8px;\n    cursor: pointer;\n}\n\n.message-actions {\n    display: flex;\n    flex-direction: column;\n}\n\n.message-input {\n    display: flex;\n    padding: 16px;\n    background-color: #ffffff;\n    border-top: 1px solid #e0e0e0;\n}\n\n.message-input .adm-input {\n    flex: 1;\n    margin-right: 8px;\n}\n\n.message-input .adm-button {\n    padding: 0 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
