import React, { useState } from 'react';
import { Card, Space, Progress, Tag, Button, Badge, message, DatePicker, Modal, Flex } from 'antd';
import { ClockCircleOutlined, InboxOutlined, MessageOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import api from '../../services/api';
import MessagesModal from './MessagesModal';

const RoomsCards = ({ rooms, onRoomsUpdate }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [newExpiryDate, setNewExpiryDate] = useState(null);
    const [messagesModalVisible, setMessagesModalVisible] = useState(false);
    const [currentRoomId, setCurrentRoomId] = useState(null);

    const calculateTotalDays = (createdAt, expiresAt) => {
        const createdDate = dayjs(createdAt);
        const expireDate = dayjs(expiresAt);
        return expireDate.diff(createdDate, 'day') + 1;
    };

    const calculateDaysLeft = (expiresAt) => {
        const now = dayjs();
        const expireDate = dayjs(expiresAt);
        return expireDate.diff(now, 'day');
    };

    const getProgressColor = (daysLeft) => {
        if (daysLeft <= 1) return '#f5222d';
        if (daysLeft <= 3) return '#faad14';
        return '#52c41a';
    };

    const handleArchiveToggle = async (roomId, isArchived) => {
        try {
            await api.put(`/rooms/${roomId}`, { is_archived: !isArchived });
            message.success(`Комната успешно ${isArchived ? 'разархивирована' : 'архивирована'}`);
            onRoomsUpdate();
        } catch (error) {
            message.error(`Ошибка при ${isArchived ? 'разархивировании' : 'архивировании'} комнаты`);
        }
    };

    const showModal = (room) => {
        setSelectedRoom(room);
        setNewExpiryDate(dayjs(room.expires_at));
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
            await api.put(`/rooms/${selectedRoom.id}`, {
                expires_at: newExpiryDate.toISOString()
            });
            message.success('Дата истечения успешно обновлена');
            setIsModalVisible(false);
            onRoomsUpdate();
        } catch (error) {
            message.error('Ошибка при обновлении даты истечения');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const openMessagesModal = (roomId) => {
        setCurrentRoomId(roomId);
        setMessagesModalVisible(true);
    };

    return (
        <>
            <Space direction="horizontal" size={16} wrap>
                {rooms.sort((a, b) => b.id - a.id).map((room) => {
                    const daysLeft = calculateDaysLeft(room.expires_at);
                    const progressColor = getProgressColor(daysLeft);
                    const totalDays = calculateTotalDays(room.created_at, room.expires_at);
                    const percent = ((totalDays / daysLeft) - 1) * 100;

                    return (
                        <Badge.Ribbon
                            key={room.id}
                            text={room.is_archived ? "Архив" : "Активна"}
                            color={room.is_archived ? "default" : "blue"}
                        >
                            <Card
                                title={
                                    <Flex align="center" justify={'flex-start'} gap={10}>
                                        <div>Комната #{room.id}</div>
                                        <Tag color="geekblue">{dayjs(room.created_at).format('DD.MM.YYYY')}</Tag>
                                    </Flex>
                                }
                                style={{ width: 350, height: 315 }}
                                actions={[
                                    <Button icon={<ClockCircleOutlined />} onClick={() => showModal(room)}>Время</Button>,
                                    <Button icon={<InboxOutlined />} onClick={() => handleArchiveToggle(room.id, room.is_archived)}>
                                        {room.is_archived ? 'Разархивировать' : 'В архив'}
                                    </Button>,
                                    <Button icon={<MessageOutlined />} onClick={() => openMessagesModal(room.id)}>Чат</Button>
                                ]}
                            >
                                <Space direction="vertical" size="small" style={{ width: '100%', height: '150px' }}>
                                    <Progress
                                        percent={percent}
                                        strokeColor={progressColor}
                                        format={() => `${daysLeft} дней осталось`}
                                    />
                                    <p><strong>Пациент:</strong> {room.patientName}</p>
                                    <p><strong>Специалист:</strong> {room.specialistName}</p>
                                </Space>
                            </Card>
                        </Badge.Ribbon>
                    );
                })}
            </Space>
            <Modal
                title="Изменить срок действия комнаты"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <DatePicker
                    showTime
                    value={newExpiryDate}
                    onChange={setNewExpiryDate}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Modal>
            <MessagesModal
                roomId={currentRoomId}
                visible={messagesModalVisible}
                onClose={() => setMessagesModalVisible(false)}
            />
        </>
    );
};

export default RoomsCards;