import React from 'react';
import {Card, Flex} from "antd";
// import UserActivityOverTime from "../charts/UserActivity";
// import MessageReadStatusChart from "../charts/MessageReadStatusChart";
// import RoomDurationChart from "../charts/RoomDurationChart";
// import PatientAgeWeightDistribution from "../charts/PatientAgeWeightDistribution";
// import UserRoleDistribution from "../charts/UserRoleDistribution";

const AnalyticsContent = () => {
    return (
        <div>
            <Flex align={'center'} justify={'flex-start'} style={{height: '100%'}} gap={20} wrap>
                <Card style={{ width: 400, height: 400 }}
                      title={'Аналитика'}>
                    > Графики
                </Card>
            </Flex>
        </div>
    );
}

export default AnalyticsContent;