import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './routes/PrivateRoute';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import NotFound from './pages/NotFound';
import TelegramPage from './pages/TelegramPage';
import WebApp from '@twa-dev/sdk';
import {useAuth} from "./hooks/useAuth";
import {Spin} from "antd";

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <InnerApp />
            </Router>
        </AuthProvider>
    );
};

const InnerApp = () => {
    const [isTelegramUser, setIsTelegramUser] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkTelegramUser = async () => {
            try {
                const telegramData = WebApp.initDataUnsafe;
                if (telegramData?.user) {
                    console.log('Telegram user detected');
                    setIsTelegramUser(true);

                    const username = telegramData.user.username;
                    const password = telegramData.user.id.toString();

                    const success = await auth.login({ username, password });
                    if (success) {
                        navigate('/telegram');
                    } else {
                        navigate('/404');
                    }
                }
            } catch (error) {
                console.error('Ошибка при инициализации Telegram Web App', error);
            }
        };

        if (!auth.isLoading) {
            checkTelegramUser();
        }
    }, [auth.isLoading, auth.login, navigate]);

    useEffect(() => {
        if (!auth.isLoading) {
            if (auth.user) {
                if (location.pathname === '/') {
                    if (auth.user.role === 'specialist') {
                        navigate('/');
                    } else {
                        navigate('/');
                    }
                }
            } else if (location.pathname !== '/login') {
                navigate('/login');
            }
        }
    }, [auth.isLoading, auth.user, location.pathname, navigate]);

    if (auth.isLoading) {
        return <Spin spinning fullscreen />;
    }

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        {auth.user?.role === 'specialist' ? <TelegramPage /> : <Dashboard />}
                    </PrivateRoute>
                }
            />
            <Route
                path="/users"
                element={
                    <PrivateRoute>
                        <Users />
                    </PrivateRoute>
                }
            />
            <Route path="/telegram" element={<PrivateRoute><TelegramPage /></PrivateRoute>} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
    );
};

export default App;