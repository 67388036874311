import React, { useState, useEffect, useRef } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Select, Button, Tag, Space } from 'antd';
import {EditOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import api from "../../services/api";

const { Option } = Select;

const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {
    let inputNode;

    if (dataIndex === 'role') {
        inputNode = (
            <Select>
                <Option value="admin">ADMIN</Option>
                <Option value="specialist">SPECIALIST</Option>
                <Option value="patient">PATIENT</Option>
            </Select>
        );
    } else if (inputType === 'number') {
        inputNode = <InputNumber />;
    } else {
        inputNode = <Input />;
    }

    return (
        <td {...restProps} style={{ whiteSpace: 'pre-wrap' }}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: ['username', 'last_name', 'first_name', 'role'].includes(dataIndex),
                            message: `Please input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const UsersTable = () => {
    const [users, setUsers] = useState([]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await api.get('/users');
                setUsers(
                    response.data.map((user) => ({
                        key: user.telegram_id,
                        telegram_id: user.telegram_id,
                        username: user.username,
                        first_name: user.first_name || '',
                        last_name: user.last_name || '',
                        middle_name: user.middle_name || '',
                        full_name: [user.last_name, user.first_name, user.middle_name].filter(Boolean).join(' '),
                        role: user.role,
                        age: user.age || '',
                        diagnosis: user.diagnosis || '',
                        weight: user.weight || '',
                        position: user.position || '',
                        created_at: new Date(user.created_at).toLocaleString('ru-RU', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        }),
                    }))
                );
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Искать ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Искать
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Сбросить
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Фильтр
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Закрыть
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const isEditing = (record) => record.telegram_id === editingKey;

    const edit = (record) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.telegram_id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (telegram_id) => {
        setLoading(true);
        try {
            const row = await form.validateFields();
            const newData = [...users];
            const index = newData.findIndex((item) => telegram_id === item.telegram_id);
            if (index > -1) {
                const item = newData[index];
                const editableFields = ['first_name', 'last_name', 'middle_name', 'age', 'diagnosis', 'weight', 'position', 'role'];
                const updatedUser = editableFields.reduce((acc, field) => {
                    if (field === 'age' && row[field] !== undefined) {
                        acc[field] = parseInt(row[field], 10);
                    } else if (field === 'weight' && row[field] !== undefined) {
                        acc[field] = parseFloat(row[field]);
                    } else {
                        acc[field] = row[field] !== undefined ? row[field] : null;
                    }
                    return acc;
                }, {});

                // Send PUT request
                await api.put(`/users/${telegram_id}`, updatedUser);

                // Update local data
                newData.splice(index, 1, { ...item, ...updatedUser });
                setUsers(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.error('Error saving user:', errInfo);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'telegram_id',
            key: 'telegram_id',
            sorter: (a, b) => a.telegram_id - b.telegram_id,
            ...getColumnSearchProps('telegram_id'),
            width: 100,
        },
        {
            title: 'Telegram',
            dataIndex: 'username',
            key: 'username',
            sorter: (a, b) => a.username.localeCompare(b.username),
            ...getColumnSearchProps('username'),
            editable: true,
            width: 150,
        },
        {
            title: 'Фамилия',
            dataIndex: 'last_name',
            key: 'last_name',
            sorter: (a, b) => a.last_name.localeCompare(b.last_name),
            ...getColumnSearchProps('last_name'),
            editable: true,
        },
        {
            title: 'Имя',
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: (a, b) => a.first_name.localeCompare(b.first_name),
            ...getColumnSearchProps('first_name'),
            editable: true,
        },
        {
            title: 'Отчество',
            dataIndex: 'middle_name',
            key: 'middle_name',
            sorter: (a, b) => a.middle_name.localeCompare(b.middle_name),
            ...getColumnSearchProps('middle_name'),
            editable: true,
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
            sorter: (a, b) => a.role.localeCompare(b.role),
            render: (role) => {
                let color = role === 'admin' ? 'volcano' : role === 'specialist' ? 'green' : 'blue';
                return <Tag color={color}>{role.toUpperCase()}</Tag>;
            },
            editable: true,
        },
        {
            title: 'Возраст',
            dataIndex: 'age',
            key: 'age',
            sorter: (a, b) => a.age - b.age,
            ...getColumnSearchProps('age'),
            editable: true,
            render: (age) => (age !== null && !isNaN(age) ? age : ''),
        },
        {
            title: 'Диагноз',
            dataIndex: 'diagnosis',
            key: 'diagnosis',
            sorter: (a, b) => a.diagnosis.localeCompare(b.diagnosis),
            ...getColumnSearchProps('diagnosis'),
            editable: true,
            width: 170
        },
        {
            title: 'Вес',
            dataIndex: 'weight',
            key: 'weight',
            sorter: (a, b) => a.weight - b.weight,
            ...getColumnSearchProps('weight'),
            editable: true,
            render: (weight) => (weight !== null && !isNaN(weight) ? weight : ''),
        },
        {
            title: 'Позиция',
            dataIndex: 'position',
            key: 'position',
            sorter: (a, b) => a.position.localeCompare(b.position),
            ...getColumnSearchProps('position'),
            editable: true,
        },
        {
            title: 'Создан',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        },
        // Update the render method in the columns definition
        {
            title: 'Действия',
            key: 'action',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                <Typography.Link onClick={() => save(record.telegram_id)} style={{ marginRight: 8 }}>
                    Сохранить
                </Typography.Link>
                <Popconfirm title="Отменить?" onConfirm={cancel}>
                    <button style={{ background: 'none', border: 'none', color: '#1890ff', cursor: 'pointer', padding: 0 }}>
                        Отмена
                    </button>
                </Popconfirm>
            </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        <Button type="primary" icon={<EditOutlined />} />
                    </Typography.Link>
                );
            },
        }
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' || col.dataIndex === 'weight' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={users}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{ pageSize: 20 }}
                scroll={{ x: 'max-content'}}
                loading={loading}
                size={'small'}
            />
        </Form>
    );
};

export default UsersTable;
