import React from 'react';
import UsersTable from "../specific/UsersTable";

const UsersContent = () => {
  return (
    <div>
      <UsersTable />
    </div>
  );
}

export default UsersContent;